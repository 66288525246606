import { AdminData } from './admin';

export type UserInvitationData = {
  email: string;
  userId: string;
  organizerId?: number;
};

export type UserData = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  isEmailConfirmed: boolean;
  invited: boolean;
  registered: boolean;
};

export type UserDataWithRoles = UserData & {
  roles: RoleData[];
};

export type SaveUserDto = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  registered: boolean;
};

export type RequestedCompanyData = {
  taxNumber: string;
  companyName: string;
  postalCode: string;
  city: string;
  addressDetails: string;
};

export type CompanyAddress = {
  id: number;
  organizerId: number;
  country: string;
  postalCode: string;
  city: string;
  addressDetails: string;
  addressType: string;
};

export type OrganizerData = {
  id: number;
  name: string;
  companyName: string;
  taxNumber: string;
  vatStatus: string;
  representativeName: string;
  bankAccountNumber: string;
  registeredNumber: string;
  registerComment: string;
  contractForm: string;
  url: string;
  address?: CompanyAddress;
  mailingAddress?: CompanyAddress;
};

export type UserOrganizerData = {
  id: number;
  userId: number;
  name: string;
  companyName: string;
  vatStatus: string;
  contractForm: string;
  currency: string;
  boxOfficeIs: boolean;
  adminData: AdminData | null;
};

export type UserHistoryData = {
  id: number;
  organizerId: number;
  modifierUserName: string;
  modifierUserRole: string;
  modifierUserEmail: string;
  modifierUserPhone: string;
  modifiedUserName: string;
  modifiedUserRole: string;
  modifiedUserEmail: string;
  modifiedUserPhone: string;
  action: string;
  events: string;
  newRole: string;
  status: boolean;
  createdAt: Date;
  payload: string;
  response: string;
};

export type RoleData = {
  id: number;
  name: string;
  userId: number;
  organizerId: number;
  isOrganizer: boolean;
  isAllEvents: boolean;
  isConfirmed: boolean;
  userData?: UserData;
  organizerData?: OrganizerData;
  events: number[];
  permissions: number[];
};

export enum UserActionType {
  USER_INVITE = 'userInvite',
  USER_EDIT_ROLE = 'userEditRole',
  USER_ADD_ROLE = 'userAddRole',
}

export enum Currency {
  EUR = '€',
  HUF = 'Ft',
}
